body {
    display: flex;
    width: 100%;
    min-height: 100%;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
}

body {
    color: #333333;
    font-size: 14px;
    background-color: white;

    svg {
        .fill-stroke {
            stroke: #333333;
        }

        .fill {
            fill: #333333;
        }
    }
}

.main-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

body.dark.normal {
    color: white;
    background-color: #474747;

    svg {
        .fill-stroke {
            stroke: white;
        }

        .fill {
            fill: white;
        }
    }
}
