.base-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999;
}

.base-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 0;
    border-radius: 6px;
    padding: 0;
    background: white;
    overflow: auto;
    outline: none;

    .content-wrapper {
        padding: 10px;
        width: 294px;
    }

    .title-wrapper {
        display: grid;
        grid-template-columns: 1fr 20px;
        color: #333333;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
    }

    .title {
        padding: 6px;
    }

    .close-button {
        display: block;
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
    }
}

body.dark.normal {
    .base-dialog {
        background: #474747;

        .title-wrapper {
            color: white;
        }
    }
}
