.sfp-forecast-row-wrapper {
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid;
    border-color: #e4e4e4;

    &.single {
        grid-template-columns: 67px 1fr 2fr;
    }
    &.multiple {
        grid-template-columns: 67px 1fr 1fr 1fr;
    }

    &.sfp-forecast-row-3-columns {
        grid-template-columns: 67px 1fr 2.2fr;
    }

    .link {
        text-decoration: none;
    }

    .sfp-forecast-row-number {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px;
        width: 55px;
        height: 34px;
        border-radius: 3px;
        color: #ffffff;
        font-weight: 400;
        font-size: 16px;
    }

    .description {
        font-size: 16px;
        margin: 6px;
    }
}

body.dark.normal {
    .sfp-forecast-row-wrapper {
        border-color: rgba(255, 255, 255, 0.2);
    }
}
